import noImg from './noImg.svg'
import cursor from './cursor.svg'
import CopyedUserIcon from ".././assets/Component 2.svg";
import EditUserIcon from ".././assets/Component 2 (1).svg";
import BlockUserIcon from ".././assets/Vector.svg";
import DeleteUserIcon from ".././assets/Component 2 (2).svg";
import backIcon from '.././assets/backIcon.svg'
import logo from ".././assets/logowhite.svg";
import CopyUser from '../assets/Group (1).svg'
import logos from "../assets/logo.svg";
import Edit from "../assets/Edit.svg";
import Check from "../assets/Check_Mark.svg";
import Reject from "../assets/Reject.svg";
import Deactive from "../assets/Deactive.svg";
import Warning from "../assets/Warning.svg";
import DeleteUsersIcon from ".././assets/deleteIcon.svg";
import checkIcon from '../assets/checkIcon.svg';
import TeamUpdateIcons from '../assets/teamUpdateIcons.svg';
import DeleteIcons from "../assets/deleteIcons.svg";
import Activate from "../assets/activate.svg";
import Pending from "../assets/pending.svg";
import Deactivate from "../assets/deactivate.svg"
import Approve from "../assets/approve.svg";
import noUser from "../assets/NoUser.svg";
import call from "../assets/call.svg";
import email from "../assets/email.svg";
import web from "../assets/web.svg";
import filter from "../assets/filter.svg"
import mail from '../assets/Mail.svg';
import editContact from '../assets/EditContact.svg';
import callContact from '../assets/CallContact.svg';
import whatsApp from '../assets/WhatsApp.svg';
import RevoIconOfferLetter from '../assets/RevoIconOfferLetter.svg';
import arrowContain from "../assets/arrowcontain.svg";
// import taxSign from " ../../assets/taxSign.svg";
import calls from "../assets/calls.svg";
import conversation from "../assets/conversation.svg"
import notes from "../assets/notes.svg"
import meetings from "../assets/meetings.svg"
import statusChange from "../assets/statusChange.svg"
import booking from './menuIcons/booking.svg';
import celender from './menuIcons/celender.svg';
import contact from './menuIcons/contact.svg';
import conversationIcon from './menuIcons/conversation.svg';
import marketing from './menuIcons/marketing.svg';
import payment from './menuIcons/payment.svg';
import report from './menuIcons/report.svg'



export const svg = {
    noImg: noImg,
    cursor: cursor,
    noUser: noUser,
    CopyedUserIcon: CopyedUserIcon,
    EditUserIcon: EditUserIcon,
    BlockUserIcon: BlockUserIcon,
    DeleteUserIcon: DeleteUserIcon,
    backIcon: backIcon,
    logo: logo,
    CopyUser: CopyUser,
    logos: logos,
    Edit: Edit,
    Check: Check,
    Reject: Reject,
    Deactive: Deactive,
    Warning: Warning,
    DeleteUsersIcon: DeleteUsersIcon,
    checkIcon: checkIcon,
    TeamUpdateIcons: TeamUpdateIcons,
    DeleteIcons: DeleteIcons,
    Activate: Activate,
    Pending: Pending,
    Deactivate: Deactivate,
    Approve: Approve,
    Call: call,
    Email: email,
    Web: web,
    filter: filter,
    Mail: mail,
    WhatsApp: whatsApp,
    EditContact: editContact,
    CallContact: callContact,
    arrowContain: arrowContain,
    calls: calls,
    conversation: conversation,
    notes: notes,
    meetings: meetings,
    statusChange: statusChange,
    Booking: booking,
    Celender: celender,
    Contact: contact,
    Conversation: conversationIcon,
    Payment: payment,
    Report: report,
    MarketIcon: marketing
    // taxSign : taxSign,

}