import React from "react";
import { BoarderBox } from "../../../myComponent/BoarderBox";
import { Avatar, Box, HStack } from "@chakra-ui/react";
import ColumnItem from "../../../myComponent/ColumnItem";
import { formatDate } from "../../../useFunctions/commonFunctions.js";
import { useNavigate } from "react-router-dom";

const LeadListItem = ({
  item,
  onClickCheckbox,
  onClickBox,
  onClickCall,
  onClickConversations,
  onClickNotes,
  onClickMeetings,
  onStatusChange,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/lead/${item._id}`);
  };

  console.log("lead item", item);


  return (
    <BoarderBox
      onClickCheckbox={onClickCheckbox}
      onClickCall={onClickCall}
      onClickConversations={onClickConversations}
      onClickNotes={onClickNotes}
      onClickMeetings={onClickMeetings}
      onStatusChange={onStatusChange}
      onClickBox={onClickBox}
      showCalls
      showConversation
      showNotes
      ShowMeetings
      showStatusChange
    >
      <HStack
        width={"100%"}
        alignItems={"flex-start"}
        marginStart={"15px"}
        padding={"15px 0px"}
      >
        <Avatar
          size="sm"
          name={item?.name || null}
          color={"white"}
          fontWeight={"900"}
          marginTop={"5px"}
        />
        <Box width={"100%"} marginStart={"5px"}>
          <HStack width={"100%"} alignItems={"flex-start"}>
            <ColumnItem
              title={"Lead Name :"}
              value={item?.name || "N/A"}
              width={"20%"}
              noOfLines={2}
            />
            <ColumnItem
              title={"Email Address :"}
              value={item?.email || "N/A"}
              width={"35%"}
            />{" "}
            <ColumnItem
              title={"Mobile Number :"}
              value={item?.mobile || "N/A"}
              width={"22%"}
            />
            <ColumnItem
              title={"WhatsApp Number :"}
              value={item?.whatsappNumber || "N/A"}
              width={"22%"}
            />
          </HStack>
          <ColumnItem
            title={"Remark :"}
            marginTop={2}
            value={item?.remark?.remarkBody || "N/A"}
            width={"90%"}
          />
        </Box>
      </HStack>
    </BoarderBox>
  );
};

export default LeadListItem;
