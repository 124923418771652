import { Box, HStack, Image, VStack } from '@chakra-ui/react'
import React from 'react'
import { CustomText } from './CustomText'
import { font } from '../consts/font'
// import { CustomBtn } from './CustomBtn'
import { color } from '../consts/color'
// import { ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom';
import { svg } from '../assets/svg.js';

const MyContainer = ({
    children,
    header,
    subHeader,
    subHeaderTitle,
    subHeaderTitleList,
    btnComponent,
    childContainer,
    isBack,
    arrowContain,
    arrowContains,
    isheaderWhite,
}) => {
    const navigate = useNavigate();
    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            flexDirection="column"

            // paddingEnd={'16px'}
            bgColor={'#F9F9F9'}
            minWidth={'900px'}
        >
            <Box
                width="100%"
                flex="0 1 auto"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={'10px 25px 10px 15px'}
                bgColor={isheaderWhite ? 'white' : null}
            >
                <HStack
                    justifyContent={'flex-start'}
                    gap={'20px'}
                >
                    {isBack && <Image
                        src={svg.backIcon}
                        alt="No Data"
                        objectFit="contain"
                        onClick={() => navigate(-1)}
                        height={'25px'}
                        alignSelf={'baseline'}
                        mt={'12px'}
                    />}
                    <VStack
                        alignItems="flex-start"
                        width="100%">
                        <CustomText
                            fontFamily={font.Oswald}
                            fontSize="30px"
                            fontWeight="500"
                        >
                            {header || 'N/A'}
                        </CustomText>
                        <Box display={'flex'}>
                            <CustomText
                                fontFamily={font.Oswald}
                                fontSize="16px"
                                fontWeight="400"
                                textAlign='left'
                                textDecoration='underline'
                                color={color.secondaryBtn}
                                gap={'0rem'}
                            >
                                {subHeader}
                            </CustomText>
                            {arrowContain && <Image
                                src={svg.arrowContain}
                                alt="No Data"
                                objectFit="contain"
                                height={'10px'}
                                padding={'0px 6px'}
                                marginTop={'10px'}
                            />}
                            <CustomText
                                fontFamily={font.Oswald}
                                fontSize="16px"
                                fontWeight="400"
                                textAlign='left'
                                color={color.secondaryBtn}
                                gap={'0rem'}
                            >
                                {subHeaderTitle}
                            </CustomText>

                            {arrowContains && <Image
                                src={svg.arrowContain}
                                alt="No Data"
                                objectFit="contain"
                                height={'10px'}
                                padding={'0px 6px'}
                                marginTop={'10px'}
                            />}
                            <CustomText
                                fontFamily={font.Oswald}
                                fontSize="16px"
                                fontWeight="400"
                                textAlign='left'
                                color={color.secondaryBtn}
                                gap={'0rem'}
                            >
                                {subHeaderTitleList}
                            </CustomText>

                        </Box>
                    </VStack>
                </HStack>
                <Box
                    display="flex"
                    gap="20px"
                    alignItems={'center'}
                >
                    {btnComponent}
                </Box>
            </Box>
            <Box
                width="100%"
                flex="1 1 auto"
                overflow="auto"
            >
                <Box
                    // padding={'10px'}
                    style={{
                        marginLeft: '10px',
                        marginRight: '50px',
                        paddingTop: '10px',
                        ...childContainer
                    }}
                > {children}</Box>
            </Box>
        </Box>
    )
}

export default React.memo(MyContainer);